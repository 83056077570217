import "./App.css";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from "./pages/Home";
import MagCoinPlsPage from "./pages/MagCoinPlsPage";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/mogcoinpls" element={<MagCoinPlsPage />} />
        </Routes>
      </BrowserRouter>

    </>
  );
}

export default App;
