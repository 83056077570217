import React, { useState } from "react";
import { IoCopyOutline, IoCheckmarkOutline } from "react-icons/io5";
import { Social } from "../Social/Social";
const testimonials = [
  {
    quote: "8db4",
  },
  {
    quote: "Y7kW",
  },
  {
    quote: "H3K3",
  },
  {
    quote: "hR5C",
  },
  {
    quote: "zQuw",
  },
  {
    quote: "A3Mc",
  },
  {
    quote: "zoiX",
  },
  {
    quote: "mNQM",
  },
  {
    quote: "yMqZ",
  },
  {
    quote: "PK4D",
  },
  {
    quote: "AndY",
  },
  {
    quote: "8db4",
  },
  {
    quote: "Y7kW",
  },
  {
    quote: "H3K3",
  },
  {
    quote: "hR5C",
  },
  {
    quote: "zQuw",
  },
  {
    quote: "A3Mc",
  },
  {
    quote: "zoiX",
  },
  {
    quote: "mNQM",
  },
  {
    quote: "yMqZ",
  },
  {
    quote: "PK4D",
  },
  {
    quote: "AndY",
  },
];

export default testimonials;

export const Hero = () => {
  const address = "0x495d9b70480A22a82D0FB81981480764BA55550e";

  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(address)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  return (
    <div>
      {" "}
      <div className="flex  z-30 overflow-hidden  text-black bg-primary  relative w-full h-fit xxl:px-4">
        <div className="flex items-center justify-center gap-2 py-2 z-30 text-black relative w-full h-fit">
          <h1 className="font-MonumentExtended break-all text-[26px] md:text-base sm:text-xs text-center">
            {address}
          </h1>
          <div
            className="flex-none cursor-pointer z-20"
            onClick={handleCopyClick}
          >
            {isCopied ? (
              <IoCheckmarkOutline className="text-2xl md:text-xl sm:text-lg" />
            ) : (
              <IoCopyOutline className="text-2xl md:text-xl sm:text-lg" />
            )}
          </div>
        </div>
      </div>
      <div className="min-h-[900px] lg:min-h-0 herobg pt-10 lg:pt-5 relative ">
        <img
          src="/assets/images/Star 1 (1).png"
          alt="Star"
          className="absolute right-0 top-0 xxxl:hidden"
        />
        <div className="max-w-[1700px] mx-auto">
          <div className="flex flex-row-reverse lg:flex-col justify-center items-center gap-20 lg:gap-10 text-white">
            {/* 1 section */}
            <div className="w-full xxl:px-4 ms-[-0px] xxl:ms-auto lg:mx-auto relative lg:mb-[-50px]">
              <img
                src="/assets/images/MogScene_01_noBG.gif"
                alt="andy tate hero image. Andy from the boys club combined with andrew tate"
                className="size-[90%] lg:mx-auto md:size-full bounce-animation md:w-full md:h-full z-10 relative"
              />
            </div>
            
            {/* 2 section */}
            <div className="w-[50%] lg:w-full flex-1 xxl:px-4 lg:mt-[-20px]">
              <h1 className="font-MonumentExtendedUltrabold text-[100px] xxl:text-6xl md:text-7xl xs:text-4xl uppercase lg:text-center">
                $mog
              </h1>

              <div className="text-nowrap xxl:text-wrap">
                <h2 className="font-MonumentExtended lg:text-center text-[35px] z-10 relative md:text-2xl md:mt-3 uppercase leading-[2.3] lg:leading-[1.8]">
                  <span className="text-accent">MOG</span> IS A PRC-20 CULTURE COIN  
                  <br className="lg:hidden" /> ON THE  <span className="text-accent">PULSE</span> BLOCKCHAIN

                  
                </h2>
              </div>
              
              <div className="mt-10 lg:mt-5">
                <Social />
              </div>
            </div>

            {/* 3 section */}
            <div className="xxl:hidden rotate-90 z-30 bg-primary text-black py-1.5 absolute -right-[500px] w-fit h-fit "></div>
          </div>
        </div>
      </div>
    </div>
  );
};
