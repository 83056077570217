import Car from "../components/Car/Car";
import { Hero } from "../components/Hero/Hero";
import About from "../components/About/About";
import Supply from "../components/Supply/Supply";

export default function Home() {
    return (
        <>
            <div className="nest">
                <Hero />
                <Car />
                <About />
                <Supply />
            </div>
        </>
    )
}
