import React, { Suspense, lazy } from 'react';

// Lazy loading all components
const Hero = lazy(() => import("../components/NewHero/Hero"));
const About = lazy(() => import("../components/NewAbout/About"));
const Car = lazy(() => import("../components/NewCar/Car"));
const Supply = lazy(() => import("../components/NewSupply/Supply"));
const Plateform = lazy(() => import("../components/NewPlateform/Plateform"));
const Trade = lazy(() => import("../components/NewTrade/Trade"));
const HowToBuy = lazy(() => import("../components/Howtobuy/HowToBuy"));
const Bridge = lazy(() => import("../components/Bridge/Bridge"));
const Info = lazy(() => import("../components/Info/Info"));

export default function MagCoinPlsPage() {
    return (
        <div
            className="bg-fixed object-cover bg-cover overflow-hidden w-screen min-h-screen relative"
            style={{ 
                backgroundImage: "url('assets/images/coin_bg.jpeg')",
                backgroundColor: "#000",
                maxWidth: '100vw'
            }}
        >
            <div className="max-w-full overflow-x-hidden">
                <Suspense fallback={<div className='text-center'>Loading Info...</div>}>
                    <Info />
                </Suspense>
                <Suspense fallback={<div className='text-center'>Loading Hero...</div>}>
                    <Hero />
                </Suspense>
                <Suspense fallback={<div className='text-center'>Loading About...</div>}>
                    <About />
                </Suspense>
                <Suspense fallback={<div className='text-center'>Loading Car...</div>}>
                    <Car />
                </Suspense>
                <Suspense fallback={<div className='text-center'>Loading HowToBuy...</div>}>
                    <HowToBuy />
                </Suspense>
                <Suspense fallback={<div className='text-center'>Loading Plateform...</div>}>
                    <Plateform />
                </Suspense>
                <Suspense fallback={<div className='text-center'>Loading Bridge...</div>}>
                    <Bridge />
                </Suspense>
                <Suspense fallback={<div className='text-center'>Loading Trade...</div>}>
                    <Trade />
                </Suspense>
                <Suspense fallback={<div className='text-center'>Loading Supply...</div>}>
                    <Supply />
                </Suspense>
            </div>
        </div>
    );
}
