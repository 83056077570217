import React from "react";
import { InfinityText } from "../InfinityText";

// Just enter your messages here
const MESSAGES = [
  "MOG COIN PRC-20 FROM PULSECHAIN ",
  "EFFORTLESS COSMIC DOMINATION",
  "TOKENIZED WINNING",
  "MOG OR GET MOGGED",
  "HIGHER",
  "NOW AVAILABLE ON ETHEREUM",
];

// Helper function to add separators between messages
const addSeparators = (messages) => {
  return messages.flatMap(message => [
    `${message}  `,  // Add spaces after each message
    "///  "         // Add separator with spaces
  ]);
};

// Generate repeated array with separators
export const testimonials1 = Array(10)
  .fill(null)
  .flatMap(() => addSeparators(MESSAGES)
  .map(message => ({ quote: message })));

const Car = () => {
  return (
    <div className="-mt-60 xxl:pt-80 xl:!pt-20 xl:mt-0 md:mt-10 relative overflow-hidden">
      <InfinityText items={testimonials1} direction="right" speed="fast" />
    </div>
  );
};

export default Car;
